<template>
    <div :class="['base-tab-list', `is-theme--${theme}`, `is-type--${type}`, `is-size--${size}`]">
        <BasePreloaderSkeleton
            v-if="isLoading"
            class="base-tab-list__loader"
        />
        <template v-else>
            <div
                v-for="tab in tabList"
                :key="tab.id"
                class="base-tab-list__item"
                :class="[
                'base-tab-list__item',
                { 'base-tab-list__item_active': getTabIsActive(tab)}
            ]"
                @click="onClickTabListItem(tab)"
            >
                {{ tab.title }}
            </div>
        </template>
    </div>
</template>

<script>
import BasePreloaderSkeleton from "@/components/Base/BasePreloaderSkeleton.vue";
import is from "vue2-datepicker/locale/es/is";

export default {
    name: 'BaseTabList',
    computed: {
        is() {
            return is
        }
    },
    components: {BasePreloaderSkeleton},
    props: {
        tabList: {
            type: Array,
            default: () => ([])
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            default: 'default',
            validator: value => ['default', 'filter', 'filter-outline'].includes(value)
        },
        type: {
            type: String,
            default: 'default',
            validator: value => ['default', 'toggle'].includes(value)
        },
        size: {
            type: String,
            default: 'default',
            validator: value => ['default', 'small'].includes(value)
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getTabIsActive(tab = {}) {
            return String(tab?.id ?? '') === this.tabIdActive;
        },
        onClickTabListItem(tab = {}) {
            this.$emit('update:tabIdActive', String(tab?.id ?? ''));
        }
    }
}
</script>

<style lang="scss" scoped>
.base-tab-list {
    display: flex;
    background: #F4F5F5;
    max-height: 36px;
    border-radius: 10px;
    padding: 5px 7px;

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 8px;
        border-radius: 10px;

        &:not(:last-child) {
            margin-right: 15px;
        }

        &_active {
            background: #4A92F6;
            color: #FFFFFF;
        }
    }

    &__loader {
        position: relative;
        width: 100%;
        height: 30px;
        //border-radius: var(--d-border-radius--2xs);
    }

    &.is-theme {
        &--filter,
        &--filter-outline {
            padding: 0;
            background-color: var(--d-color--white);
            border-radius: var(--d-border-radius--3xs);

            .base-tab-list {
                &__item {
                    padding: 5px 12px;
                    color: var(--d-color--gray-500);
                    background-color: var(--d-color--gray-200);
                    border: 1px solid transparent;
                    border-radius: var(--d-border-radius--3xs);
                    overflow: hidden;
                    transition: var(--d-transition--low);

                    &:not(:last-child) {
                        margin-right: 4px;
                    }

                    &:hover {
                        color: var(--d-color--text-primary);
                    }

                    &_active {
                        color: var(--d-color--text-primary);
                        border-color: var(--d-color--green-primary);
                        background-color: transparent;
                    }
                }
            }
        }

        &--filter-outline {
            .base-tab-list {
                &__item {
                    background-color: transparent;
                }
            }
        }
    }

    &.is-type {
        &--toggle {
            max-height: unset;
            .base-tab-list {
                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                }
            }
        }
    }

    &.is-size {
        &--small {
            border-radius: var(--d-border-radius--4xs);
            .base-tab-list {
                &__loader {
                    height: 24px;
                }
                &__item {
                    padding: 2px 10px;
                    border-radius: var(--d-border-radius--4xs);
                }
            }
        }
    }
}
</style>
